@import '~@appclose/ui/src/components/Typography/scss/mixins';
@value labelOffset: 20px;

.label {
  position: relative;
  display: block;
  padding-top: labelOffset;
  outline: none;
}

.title {
  @include text(2, 'semibold', 'secondary');

  position: absolute;
  top: labelOffset;
  left: 0;
  display: flex;
  align-items: center;
  max-width: 100%;
  transition: all 0.3s ease;
  pointer-events: none;
}

.title span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.minimize .title {
  @include caption(3, 'light', 'secondary');

  top: 0;
}

.label.error:not(.disabled) .title {
  color: var(--color-warning);
}

.tooltipIcon {
  position: absolute;
  top: calc(-1 * labelOffset);
  left: 0;
  margin-top: 2px;
  transition: all 0.3s ease;
  pointer-events: all;
}

.minimize .tooltipIcon {
  top: 0;
  left: 100%;
}

.withMinimizedLabel {
  margin-left: 6px;
}

.required {
  margin-left: 8px;
}
