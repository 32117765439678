@import '../../../../scss/functions';
@import '../../scss/variables';
@import '../../scss/mixins';
@import '../../../Typography/scss/mixins';

.button {
  @include caption(1, 'bold');

  position: relative;
  min-height: 44px;
  padding: 12px 28px;
  font-family: inherit;
  text-align: center;
  background-color: get-color('background', 'primary');
  border: none;
  border-radius: 24px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  transition:
    background-color 0.2s ease,
    box-shadow 0.2s ease;
  appearance: none;
  user-select: none;

  &::-moz-focus-inner {
    border: 0;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }

  &:not(.loading).disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button {
  display: inline-flex;
  text-decoration: none;
}

.small {
  min-height: 36px;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 16px;
}

.primary {
  @include generate-skin-style('primary', 'base');

  &:hover:not(.disabled) {
    @include generate-skin-style('primary', 'hover');
  }

  &:active:not(.disabled) {
    @include generate-skin-style('primary', 'active');
  }
}

.success {
  @include generate-skin-style('success', 'base');

  &:hover:not(.disabled) {
    @include generate-skin-style('success', 'hover');
  }

  &:active:not(.disabled) {
    @include generate-skin-style('success', 'active');
  }
}

.secondary {
  @include generate-skin-style('secondary', 'base');

  &:hover:not(.disabled) {
    @include generate-skin-style('secondary', 'hover');
  }

  &:active:not(.disabled) {
    @include generate-skin-style('secondary', 'active');
  }
}

.brand {
  @include generate-skin-style('brand', 'base');

  &:hover:not(.disabled) {
    @include generate-skin-style('brand', 'hover');
  }

  &:active:not(.disabled) {
    @include generate-skin-style('brand', 'active');
  }
}

.info {
  @include generate-skin-style('info', 'base');

  &:hover:not(.disabled) {
    @include generate-skin-style('info', 'hover');
  }

  &:active:not(.disabled) {
    @include generate-skin-style('info', 'active');
  }
}

.shell {
  @include generate-skin-style('ghost', 'base');

  &:hover:not(.disabled) {
    @include generate-skin-style('ghost', 'hover');
  }

  &:active:not(.disabled) {
    @include generate-skin-style('ghost', 'active');
  }

  &.loading {
    background-color: inherit;
    box-shadow: inherit;
    user-select: none;
    pointer-events: none;
  }
}

.link {
  @extend .shell;

  color: var(--color-primary);
  line-height: normal;
  word-break: break-word;
}

.loading {
  @include generate-skin-style('secondary', 'base');

  color: transparent;
  user-select: none;
  pointer-events: none;

  &:hover,
  &:active {
    background-color: get-color('background', 'primary');
  }

  > :not(.spinner) {
    visibility: hidden;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  transform: translate(-50%, -50%);
}
