@import '../../scss/functions';

.container {
  padding-bottom: 8px;
  border-bottom: 1px solid get-color('background', 'tertiary');
}

.chipContainer {
  padding: 2px 12px;
  background-color: get-color('background', 'tertiary');
  border-radius: 16px;
}

.input {
  min-width: 100px;
  padding-bottom: 4px;
  background: none;
  background-image: none !important;
}
