@import '~@appclose/ui/src/components/Typography/scss/mixins';
@import 'scss/variables';
@import '~@appclose/ui/src/scss/mixins';

.select {
  position: relative;
  display: grid;
  width: 100%;
}

.valueContainer {
  padding-right: 16px;
}

.hide {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.selectValue {
  @include text(2, 'semibold');

  composes: valueContainer;
  padding: 4px 20px 8px 0;
  overflow: hidden;
  font-family: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: no-repeat bottom;
  background-color: transparent;
  background-image: linear-gradient(
      0deg,
      var(--color-success) 1px,
      rgba(0, 150, 136, 0) 0
    ),
    linear-gradient(0deg, #e8ecec 1px, transparent 0) !important;
  background-size:
    0 100%,
    100% 100%;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  transition: background 0s ease-out;
}

.selectValue:focus {
  background-size:
    100% 100%,
    100% 100%;
  transition-duration: 0.3s;
}

.selectValue:empty::before {
  overflow: hidden;
  color: rgba(51, 65, 71, 0.5);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  content: attr(data-placeholder);
}

.hasError .selectValue {
  background-image: linear-gradient(
      0deg,
      var(--color-warning) 1px,
      rgba(0, 150, 136, 0) 0
    ),
    linear-gradient(0deg, #e8ecec 1px, transparent 0) !important;
  background-size:
    100% 100%,
    100% 100%;
}

.searchInput {
  composes: valueContainer;
}

.arrowIcon {
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  transition: transform ease 0.5s;
  pointer-events: none;
  fill: get-color('icons', 'gray030') !important;
}

.isOpen {
  transform: scaleY(-1);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.disabled .selectValue,
.disabled .icon {
  pointer-events: none;
}

.menu {
  z-index: 1;
  padding: 24px 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.15);
  user-select: none;
}

.options {
  max-height: 320px;
  padding: 0 $menuXPadding;
  overflow-y: auto;
}

.addNew {
  width: calc(100% + #{$menuXPadding} * 2);
  margin: 0 ($menuXPadding * -1);
  padding: 15px ($optionXPadding + $menuXPadding);
}

.hasAddNew.menu {
  padding-bottom: 0;
}

.noOptionsTitle {
  margin: 0;
  padding: 0 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
}

@include mobile {
  .menu {
    max-width: 100vw;
  }
}
