@import '~@appclose/ui/src/components/Typography/scss/mixins';
@import '~@appclose/ui/src/scss/functions';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-grow: 1;
  margin-bottom: 24px;
}

.contentWithoutName {
  margin-top: 0;
}

.buttons {
  justify-content: center;
}
