@import '~@appclose/ui/src/scss/functions';

.container {
  position: relative;
  display: flex;
  gap: 5px;
  width: 100%;
  height: 36px;
}

.input {
  color: get-color('text', 'primary');
  font-size: 1.25rem;
  font-family: inherit;
  line-height: 1.4;
  background-color: get-color('background', 'primary');
  border: none;
  border-bottom: 1px solid get-color('background', 'tertiary');
  outline: none;
  transition: border-bottom-color 0.2s ease-in-out;
}

.inactive {
  color: get-color('text', 'gray030');
  background-color: transparent;
}

.focus {
  color: get-color('text', 'primary');
  border-bottom: 1px solid get-color('brand', 'primaryYellow');
}

.error {
  border-bottom: 1px solid get-color('text', 'warning');
}
