@import '~@appclose/ui/src/scss/mixins';

@value borderRadius from '../../Table.module.scss';
@value cell from '../TableCell/TableCell.module.scss';

.row:not(:first-child) {
  border-top: 1px solid #ebeeee;
  transition: border-color ease 0.4s;
}

tbody .row:last-child {
  border-bottom: 1px solid #ebeeee;
  transition: border-color ease 0.4s;
}

tbody .row:first-child:last-child {
  border-bottom: 1px solid transparent;
  transition: border-color ease 0.4s;
}

.row .cell {
  transition: background-color ease 0.3s;
}

.row .cell:first-child {
  border-top-left-radius: borderRadius;
  border-bottom-left-radius: borderRadius;
}

.row .cell:last-child {
  border-top-right-radius: borderRadius;
  border-bottom-right-radius: borderRadius;
}

@include desktop {
  .hover:hover,
  .hover:hover + .row {
    border-top-color: transparent;
  }
  .hover:hover:last-child {
    border-bottom-color: transparent;
  }

  .hover:hover .cell {
    background-color: #f8f9fa;
  }
}

.clickable {
  cursor: pointer;
}
