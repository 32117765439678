@import '~@appclose/ui/src/scss/functions';

.contextMenu {
  padding: 24px 32px;
  background: get-color('background', 'primary');
  border-radius: 8px;
  border-radius: 20px;
  outline: 0;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
}

.contextMenuItem {
  display: block;
}

.overlay {
  z-index: 1;
}
