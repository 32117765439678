@import '~@appclose/ui/src/scss/functions';
@import '~@appclose/ui/src/scss/mixins';

.modal {
  width: 100%;
  max-width: 460px;
  padding: 32px 32px 48px 32px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.08);

  .main {
    position: relative;
    word-break: break-word;
  }

  .title {
    margin-bottom: 24px;
  }

  &.page {
    max-width: 720px;
    margin: 0 auto;
    padding: 80px 0 120px;
    box-shadow: none;
  }

  &.media {
    width: auto;
    max-width: 67vw;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }
}

.pageOverlay {
  align-items: flex-start;
  padding: 0;
  background-color: white;
}

.mediaOverlay {
  padding: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: get-color('background', 'primary');
  border-radius: 50%;
  transform: translate(100%, -90%);
}

@media print {
  .modal.page .header {
    display: none;
  }
}

@include mobile {
  .overlay:not(.pageOverlay) {
    padding: 10px 20px;
  }

  .modal {
    margin: auto;
    padding: 20px 20px 32px 20px;
  }

  .modal.page {
    margin: 0;
    padding: 20px 20px 58px;
  }
}
